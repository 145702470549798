








import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import Company from '@/shared/modules/company/company.model'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { AdminRoutes } from '@/shared/router/admin'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import CompanyService from '@/shared/modules/company/company.service'
import DataTableHeaderTypes from '@/shared/classes/components/data-table/data-table-header-types'
import ShowCompanySlug from '@/admin/components/views/companies/ShowCompanySlug.vue'
import permissions from '@/shared/helpers/permissions.helper'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class CompaniesIndex extends Vue {
  table: DataTableBase | null = null

  created() {
    this.table = new DataTableBase()
      .setModel(Company)
      .setEndpoint('companies')
      .setHeaders([
        new DataTableHeader()
          .setType(DataTableHeaderTypes.image)
          .setKey('logo')
          .setText(__('admin.views.companies.index.table.columns.logo'))
          .setSortable(false)
          .setMeta({ width: '40px', height: '40px' }),
        new DataTableHeader()
          .setKey('name')
          .setText(__('admin.views.companies.index.table.columns.name')),
        new DataTableHeader()
          .setKey('company_code')
          .setEntryKey('companyCode')
          .setText(__('admin.views.companies.index.table.columns.company-code')),
        new DataTableHeader()
          .setKey('vat_code')
          .setEntryKey('vatCode')
          .setText(__('admin.views.companies.index.table.columns.vat-code')),
        new DataTableHeader()
          .setKey('address')
          .setText(__('admin.views.companies.index.table.columns.address')),
        new DataTableHeader()
          .setKey('post_code')
          .setEntryKey('postCode')
          .setText(__('admin.views.companies.index.table.columns.post-code')),
        new DataTableHeader()
          .setKey('city')
          .setText(__('admin.views.companies.index.table.columns.city')),
        new DataTableHeader()
          .setKey('country.name')
          .setSortable(false)
          .setText(__('admin.views.companies.index.table.columns.country')),
        new DataTableHeader()
          .setKey('break_minutes')
          .setEntryKey('breakMinutes')
          .setSortable(false)
          .setText(__('admin.views.companies.index.table.columns.break')),
      ])
      .setActions([
        new DataTableAction()
          .setIcon('mdi-power')
          .setTitle(__('admin.views.companies.index.table.actions.activate.tooltip'))
          .setPermissions([permissions.company.activate])
          .setAction((company: Company) => this.$store.dispatch(GlobalActions.showDialog, {
            show: true,
            component: AreYouSureDialog,
            meta: {
              title: __('admin.views.companies.index.table.actions.activate.title'),
              text: __('admin.views.companies.index.table.actions.activate.text', { name: company.name }),
              onYes: () => CompanyService.activate(company.uuid)
                .then(async () => {
                  await this.tableRef.refresh()

                  await this.$store.dispatch(GlobalActions.showSnackBar, {
                    type: SnackBarTypes.success,
                    message: __('admin.views.companies.index.table.actions.activate.on-success'),
                  })
                })
            }
          })),
        new DataTableAction()
          .setIcon('mdi-power-off')
          .setTitle(__('admin.views.companies.index.table.actions.deactivate.tooltip'))
          .setPermissions([permissions.company.deactivate])
          .setAction((company: Company) => this.$store.dispatch(GlobalActions.showDialog, {
            show: true,
            component: AreYouSureDialog,
            meta: {
              title: __('admin.views.companies.index.table.actions.deactivate.title'),
              text: __('admin.views.companies.index.table.actions.deactivate.text', { name: company.name }),
              onYes: () => CompanyService.deactivate(company.uuid)
                .then(async () => {
                  await this.tableRef.refresh()

                  await this.$store.dispatch(GlobalActions.showSnackBar, {
                    type: SnackBarTypes.success,
                    message: __('admin.views.companies.index.table.actions.deactivate.on-success'),
                  })
                })
            }
          })),
        new DataTableAction()
          .setIcon('mdi-link')
          .setTitle(__('admin.views.companies.index.table.actions.link'))
          .setAction((company: Company) => this.$store.dispatch(GlobalActions.showDialog, {
            show: true,
            component: ShowCompanySlug,
            maxWidth: 400,
            meta: {
              company,
            }
          })),
        new EditAction(),
        new DeleteAction()
          .setAction((item: Company) => this.$store.dispatch(GlobalActions.showDialog, {
            show: true,
            component: AreYouSureDialog,
            meta: {
              title: __('admin.views.companies.index.table.actions.delete.title'),
              text: __('admin.views.companies.index.table.actions.delete.text', { name: item.name }),
              onYes: () => CompanyService.destroy(item.uuid)
                .then(() => this.table && this.table.removeItem(item))
            }
          }))
      ])
  }

  navigateToCreate(): void {
    this.$router.push({ name: AdminRoutes.companiesCreate })
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
