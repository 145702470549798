












import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { CompanyRoutes } from '@/shared/router/company'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'

@Component({
  methods: { __ }
})
export default class ShowCompanySlug extends Vue {
  @Prop() meta: any

  get url(): string {
    return window.location.origin + this.$router.resolve({
      name: CompanyRoutes.index,
      params: {
        company: this.meta.company.slug
      }
    }).href
  }

  copySlug(): void {
    const slugInput: any = this.$refs.slugInput
    const textToCopy = slugInput.$el.querySelector('input')
    textToCopy.select()
    document.execCommand('copy')
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('admin.components.views.companies.show-company-slug.success')
    })
    this.$store.dispatch(GlobalActions.closeDialog)
  }
}
